<template>
  <Card>
    <template v-slot:heading>
      <Header :dates="dates" />
    </template>
    <CustomerSelect
      class="w-100 me-2"
      :options="masters.customers"
      v-model="form.customer"
      @update="handleUpdateOptions"
    />
    <Select
      label="Contacts"
      name="contacts"
      optionLabel="name"
      :options="form.customer.contacts"
      v-model="form.contacts"
    />
    <Input
      label="CC"
      placeholder="comma separated email addresses"
      v-model="form.cc"
    />
    <TextArea
      :rows="12"
      label="Body"
      note="(this text will appear as introduction paragraph in the Email)"
      v-model="form.body"
    />
    <Footer :count="artists.length">
      <Button @click="goBack">
        Back
      </Button>
      <Button @click="onSubmit">
        Send Email
      </Button>
    </Footer>
  </Card>
</template>
<script>
import Header from "@/Components/Search/Header.vue";
import Footer from "@/Components/Search/Footer.vue";
import CustomerSelect from "@/Components/CustomerSelect.vue";
import axios from "axios";
export default {
  props: {
    dates: {
      type: Array,
      default: () => []
    },
    artists: {
      type: Array,
      default: () => []
    },
   path: {
     type: String,
   }
  },
  components: {
    Footer,
    Header,
    CustomerSelect
  },
  data() {
    return {
      form: {
        cc: "",
        body: "",
        customer: {
          contacts: []
        },
        contacts: []
      },
      masters: {
        customers: []
      }
    };
  },
  computed: {
    customerId() {
      return this.form.customer.id;
    }
  },
  watch: {
    customerId() {
      // Reset previous customer's selected contacts
      this.form = {
        ...this.form,
        contacts: []
      };
    }
  },
  methods: {
    handleUpdateOptions(customers) {
      this.masters = {
        ...this.masters,
        customers
      };
    },
    goBack() {
      window.history.back();
    },
    onSubmit() {
      const partial_availability = window.location.search.includes('partial_availability') ? 1 : 0;
      const { form } = this;
      axios
        .post(this.path, {
          contacts: form.contacts.map(c => c.id),
          cc: form.cc,
          body: form.body,
          dates: this.dates,
          artists: this.artists,
          partial_availability
        })
        .then(() => alert("Email sent successfully."))
        .catch(() => alert("There was a problem in sending email"));
    }
  }
};
</script>
