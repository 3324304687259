<template>
  <Card>
    <template v-slot:heading>
      <Header :dates="dates" />
    </template>
    <template v-slot:actions>
      <div class="d-inline-flex gap-2 align-items-center justify-content-between">
        <Checkbox name="selectAll" label="Select All?" :value="isAllArtistSelected" @input="handleSelectAllArtists" />
        <button class="btn btn-outline-secondary btn-sm" @click.prevent="togglePortfolio">
          <i class="bi me-2" :class="{ 'bi-eye': !showPortfolio, 'bi-eye-slash': showPortfolio }" />
          <span>Toggle Portfolio Images</span>
        </button>
      </div>
    </template>

    <ArtistList v-for="group in groups" :key="group.name" :group="group" :artists="groupedArtists(group)"
      @selectGroup="handleSelectGroup" @selectArtist="handleSelectArtist" :showPortfolio="showPortfolio" />
    <Footer :count="selectedCount">
      <div v-if="booking_id">
        <Button @click="handleAddToBooking" :disabled="selectedCount === 0">
          Add To Booking #{{ booking_id }}
        </Button>
      </div>
      <div class="btn-group" v-else>
        <Button data-bs-toggle="modal" data-bs-target=".modal" :disabled="selectedCount === 0"
          :secondary="true">Share</Button>
        <Button @click="handleText" :disabled="selectedCount === 0" :secondary="true">Text</Button>
        <Button @click="handleSubmit" v-if="dates.length > 0" :disabled="selectedCount === 0 || selectedArtistsWithConfirmedBookings.length > 0">Book</Button>
        <Button @click="handleEmail" :disabled="selectedCount === 0">Email</Button>
      </div>
    </Footer>

    <div class="modal">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Share Availability</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <label class="row mb-2">
              <span class="col-4">Pick a tag</span>
              <div class="col-8">
                <select v-model="selectedTag">
                  <option v-for="tag in tags" :value="tag">{{ tag }}</option>
                </select>
              </div>
            </label>
            <label class="row mb-2">
              <span class="col-4">Select a layout</span>
              <div class="col-8">
                <select v-model="selectedLayout">
                  <option value="short">3 Portfolio Grid</option>
                  <option value="large">8 Portfolio Grid</option>
                </select>
              </div>
            </label>

            <label class="row mb-2">
              <span class="col-4">Internal Notes</span>
              <div class="col-8">
                <textarea v-model="internalNotes" class="form-control" placeholder="Enter any internal notes here"></textarea>
              </div>
            </label>
          </div>
          <div class="modal-footer">
            <button type="button" @click="handleShare('pdf')" class="btn btn-secondary">Share via PDF</button>
            <button type="button" @click="handleShare" class="btn btn-primary">Share via Link</button>
          </div>
        </div>
      </div>
    </div>
  </Card>
</template>

<script>
import ArtistList from "@/Components/Search/ArtistList.vue";
import Header from "@/Components/Search/Header.vue";
import Footer from "@/Components/Search/Footer.vue";
import Button from "@/Components/Common/Button.vue";
import uniqBy from "lodash/uniqBy";
import sortBy from "lodash/sortBy";
import { router } from '@inertiajs/vue2'
import { buildParams } from "../../utils.js";

export default {
  props: {
    result: Object,
    tags: Array,
    dates: {
      type: Array,
      default: [],
    },
    path: Object,
    booking_id: String,
  },
  components: {
    Button,
    Footer,
    ArtistList,
    Header,
  },
  data() {
    return {
      showPortfolio: false,
      artists: [],
      groups: [],
      internalNotes: "",
      selectedTag: "default",
      selectedLayout: "short"
    };
  },
  methods: {
    togglePortfolio() {
      this.showPortfolio = !this.showPortfolio;
    },
    groupedArtists(group) {
      return this.artists.filter(
        (artist) => artist.artist_master.id === group.id
      );
    },
    handleSelectAllArtists(selected) {
      this.artists = this.artists.map((artist) => {
        return {
          ...artist,
          selected,
        };
      });
    },
    handleSelectArtist(event) {
      this.artists = this.artists.map((artist) => {
        const checked =
          artist.id === event.id ? event.checked : artist.selected;
        return {
          ...artist,
          selected: checked,
        };
      });
    },
    handleSelectGroup(event) {
      this.artists = this.artists.map((artist) => {
        const checked =
          artist.artist_master.id === event.group.id
            ? event.checked
            : artist.selected;

        return {
          ...artist,
          selected: checked,
        };
      });
    },
    searchParams() {
      const { dates, selectedArtists } = this;
      return buildParams({
        dates,
        artists: selectedArtists.map((a) => a.id),
        partial_availability: this.selectedArtistsWithConfirmedBookings.length > 0 ? 1 : 0
      }).toString();
    },
    handleText() {
      router.visit(
        `${this.path.text}?${this.searchParams()}`,
        this.form
      );
    },
    handleShare(type = "link") {
      const path = type === "pdf" ? this.path.pdf : this.path.share;
      let fullPath = `${path}?${this.searchParams()}`;
      if (this.selectedTag) {
        fullPath += `&tag=${this.selectedTag}`;
      }
      if (this.selectedLayout) {
        fullPath += `&layout=${this.selectedLayout}`;
      }
      if (this.internalNotes) {
        fullPath += `&internal_notes=${this.internalNotes}`;
      }
      window.location.href = fullPath;
    },
    handleSubmit() {
      if (this.selectedArtistsWithConfirmedBookings.length > 0) {
        alert('Main Board with confirmed bookings cannot be selected for a new booking.');
        return;
      }

      router.visit(
        `${this.path.book}?${this.searchParams()}`,
        this.form
      );
    },
    handleEmail() {
      router.visit(`${this.path.email}?${this.searchParams()}`);
    },
    handleAddToBooking() {
      const bookingDates = [];
      this.dates.forEach((date) => {
        this.selectedArtists.forEach((artist) => {
          bookingDates.push({ on_date: date, artist_id: artist.id });
        });
      });
      router.post(this.path.add_to_booking, { booking_dates: bookingDates });
    },
  },
  computed: {
    selectedCount() {
      return this.selectedArtists.length;
    },
    isAllArtistSelected() {
      return this.artists.length === this.selectedArtists;
    },
    selectedArtists() {
      return this.artists.filter((a) => a.selected);
    },
    selectedArtistsWithConfirmedBookings() {
      return this.selectedArtists.filter((a) => {
        if (!a.booking_dates) return false;
        return Object.values(a.booking_dates).some(bookings => bookings.some(booking => booking.status === 'confirmed'));
      });
    },
  },
  created() {
    // Mark all of them not selected by default
    this.artists = this.result.artists.map((a) => ({
      selected: false,
      ...a,
    }));

    // Extract all the groups
    this.groups = sortBy(
      uniqBy(
        this.result.artists.map((a) => a.artist_master),
        (am) => am.id
      ),
      "priority"
    );
  },
};
</script>
