<template>
  <section class="py-2">
    <div :class="{ 'orange_sheet': artist.orange_sheet }" class="me-2 row row-cols-2">
      <div class="col">
        <div class="d-flex align-items-center">
          <Checkbox :value="artist.selected" @input="handleSelect" />
          <Artist :artist="artist" @click="handleSelect" />
        </div>
      </div>
      <div class="col">
        <ArtistScheduleLink :artist="artist" />
        <button class="btn ms-2" @click="previewOpen = !previewOpen"
          v-if="artist.pictures.length > 0">
          <i class="bi bi-images" />
        </button>
      </div>
    </div>
    <div class="row py-2" :if="artist.booking_dates.length > 0">
      <div class="col">
        <ArtistBookings :artist="artist" />
      </div>
    </div>
    <div class="row" v-if="showIt && artist.pictures.length > 0">
      <div class="d-flex col">
        <img v-for="picture in artist.pictures.slice(0, 3)" :if="artist.pictures.length > 0" :key="picture"
          :src="picture.thumbnail_url" loading="lazy" class="px-2 py-1" style="width: 250px; object-fit: cover;" />
      </div>
    </div>
  </section>
</template>

<script>
import ArtistScheduleLink from "@/Components/Search/ArtistScheduleLink.vue";
import Artist from "@/Components/Search/Artist.vue";
import ArtistBookings from "@/Components/Search/ArtistBookings.vue";

export default {
  components: {
    ArtistScheduleLink,
    Artist,
    ArtistBookings
  },
  props: ["artist", "showPortfolio"],
  data() {
    return {
      previewOpen: this.showPortfolio,
    };
  },
  methods: {
    handleSelect() {
      this.$emit('select', {
        checked: !this.artist.selected,
        id: this.artist.id
      });
    },
  },
  computed: {
    showIt() {
      return this.previewOpen || this.showPortfolio;
    },
  },
};
</script>
